// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig } from './../../@cmcore/component';
import { AssociadoEditComponent } from './associado-edit.component';

@Component({
  selector: 'associado-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class AssociadoBrowseComponent extends CMFormBrowse2Component {

  getTitle() {
    return 'Associado';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'associado';
    this.formEditComponent = AssociadoEditComponent;
    this.columns = [
      {
        field: 'id', 
        caption: '#', 
        width: '100',
        filter: new CMInputConfig({
          dataType: 'integer'
        })
      },
      {
        field: 'codigo',
        caption: 'Código',
        width: '100',
        filter: new CMInputConfig({
          dataType: 'integer'
        })
      },
      {
        field: 'cnpj', caption: 'CNPJ',  width: '200',
        filter: new CMInputConfig({
          mask: '99.999.999/9999-99'
        })
      },
      {
        field: 'nome',
        caption: 'Razão Social',
        filter: new CMInputConfig({
          maxlength: 100
        })
      },
    ];
  }

  static pesquisa(
    sender: any,
    target: any,
    tipo: string,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#" },
        {
          field: "$tipopessoa",
          caption: "Tipo",
          filter: true,
          value: tipo,
          visible: false,
          filterVisible: false,
        },
        {
          field: "nome",
          caption: "Nome",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "fantasia",
          caption: "Fantasia",
          filter: new CMInputConfig({
            maxlength: 100,
          }),
        },
        {
          field: "cpf",
          caption: "CPF",
          filter: true,
          mask: "999.999.999-99",
        },
        {
          field: "cnpj",
          caption: "CNPJ",
          filter: true,
          mask: "99.999.999/9999-99",
        },
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "associado",
        "Pesquisa de " + tipo,
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }

}