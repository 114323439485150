// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { PreVendaBrowseComponent } from "./../../prevenda/prevenda-browse.component";
import { NFeBrowseComponent } from "./../../nfe/nfe-browse.component";
import { CFeBrowseComponent, LoteContaReceberBrowseComponent, NFCeBrowseComponent } from "../..";
import { FuncsGlobais } from "../../funcs.globais";
import { CupomDescontoBrowseComponent } from "../../cupom-desconto";

@Component({
  selector: "emissao-carne",
  templateUrl: "emissao-carne.component.html",
})
export class RelatEmissaoCarneComponent extends CMFormRelatComponent {
  valuesOrigem = [];
  setValuesOrigem() {
    if (this.paramEmpresa.modoOperacao === "Nivel1") {
      this.valuesOrigem = [
        { value: "Pré-Venda" },
        { value: "CF-e" },
        { value: "NF-e" },
      ];
    } else {
      this.valuesOrigem = [
        { value: "Pré-Venda" },
        { value: "CF-e" },
        { value: "NF-e" },
        { value: "NFC-e" },
        { value: "Lote" },
        { value: "CupomDesconto", text: "Cupom de Desconto" },
      ];
    }
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  ngAfterViewInit() {
    this.filterConfig.prevenda.components[0].focus()
  }

  constructor(_injector: Injector) {
    super(_injector);

    this.relatName = "ImpressaoCarne";
    this.setValuesOrigem();
    this.filterConfig = {
      prevenda: new CMInputConfig({
        form: this,
        caption: "Pré-Venda",
        visibleFunc: () => {
          return this.filter.origem === "Pré-Venda";
        },
        pesqFunc: (sender) => {
          PreVendaBrowseComponent.pesquisa(sender, this.filter, undefined, () => {
            this.filterConfig.prevenda.components[0].focus()
          });
        },
      }),
      nfe: new CMInputConfig({
        form: this,
        caption: "NF-e",
        visibleFunc: () => {
          return this.filter.origem === "NF-e";
        },
        pesqFunc: (sender) => {
          NFeBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      nfce: new CMInputConfig({
        form: this,
        caption: "NFC-e",
        visibleFunc: () => {
          return this.filter.origem === "NFC-e";
        },
        pesqFunc: (sender) => {
          NFCeBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      lote: new CMInputConfig({
        form: this,
        caption: "Lote",
        visibleFunc: () => {
          return this.filter.origem === "Lote";
        },
        pesqFunc: (sender) => {
          LoteContaReceberBrowseComponent.pesquisa(sender, this.filter, "lote");
        },
      }),
      cupomdesconto: new CMInputConfig({
        form: this,
        caption: "Cupom de Desconto",
        visibleFunc: () => {
          return this.filter.origem === "CupomDesconto";
        },
        pesqFunc: (sender) => {
          CupomDescontoBrowseComponent.pesquisa(
            "Não",
            sender,
            this.filter,
            "cupomdesconto"
          );
        },
      }),
      cfe: new CMInputConfig({
        form: this,
        caption: "CF-e",
        visibleFunc: () => {
          return this.filter.origem === "CF-e";
        },
        pesqFunc: (sender) => {
          CFeBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      origem: new CMInputConfig({
        form: this,
        caption: "Origem",
        values: this.valuesOrigem,
      }),
      reduzido: new CMInputConfig({
        form: this,
        caption: "Impressão Reduzida",
        dataType: "boolean",
      }),
      np: new CMInputConfig({
        form: this,
        caption: "Emite Nota Promissória",
        dataType: "boolean",
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
      }),
    };
    this.filter.origem = "Pré-Venda";
    this.filter.reduzido = "Não";
    this.filter.np = "Não";
  }

  valid() {
    let r = super.valid();
    if (this.filter.origem === "Pré-Venda") {
      if (r) {
        r = !this.isEmpty(this.getValueObject(this.filter, "prevenda.id"));
        if (!r) CMFormGlobaisComponent.toastErro("Pré-Venda não informada");
      }
    } else if (this.filter.origem === "NF-e") {
      if (r) {
        r = !this.isEmpty(this.getValueObject(this.filter, "nfe.id"));
        if (!r) CMFormGlobaisComponent.toastErro("NF-e não informada");
      }
    }else if (this.filter.origem === "NFC-e") {
        if (r) {
          r = !this.isEmpty(this.getValueObject(this.filter, "nfe.id"));
          if (!r) CMFormGlobaisComponent.toastErro("NFC-e não informada");
        }
    } else if (this.filter.origem === "CF-e") {
      if (r) {
        r = !this.isEmpty(this.getValueObject(this.filter, "cfe.id"));
        if (!r) CMFormGlobaisComponent.toastErro("CF-e não informado");
      }
    }
    return r;
  }
}
