// tslint:disable

import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./../@cmcore/guard";
import {
  HomeComponent,
  HomeConsultasComponent,
  HomeDashboardComponent,
  HomeDestaquesComponent,
  Home2Component,
  AssociadoBrowseComponent,
  SeguimentoBrowseComponent,
  GrupoDeOperacaoBrowseComponent,
  MunicipioBrowseComponent,
  ProdutoBrowseComponent,
  ClienteBrowseComponent,
  FormaPagamentoBrowseComponent,
  PreVendaBrowseComponent,
  MinhaContaComponent,
  RelatClientesAniversariantesComponent,
  RelatContaReceberComponent,
  RelatEmissaoCarneComponent,
  RelatContaPagarComponent,
  RelatFluxoCaixaFuturoComponent,
  DuplicataReceberBrowseComponent,
  DuplicataPagarBrowseComponent,
  IBPTBrowseComponent,
  PadraoDeOperacaoBrowseComponent,
  CESTBrowseComponent,
  NFeBrowseComponent,
  CFOPBrowseComponent,
  ContaPagarBrowseComponent,
  NFeInutilizacaoBrowseComponent,
  CurriculumBrowseComponent,
  RelatBoletoComponent,
  RelatUsoPlataformaComponent,
  GeracaoArquivoCNABComponent,
  ImportacoesCNABComponent,
  NFeEstoqueBrowseComponent,
  RelatPosicaoEstoqueComponent,
  AjusteSaldoComponent,
  EmpresaAssociadoBrowseComponent,
  GradeBrowseComponent,
  NecessidadeCompraBrowseComponent,
  OrdemCompraBrowseComponent,
  CotacaoBrowseComponent,
  CotacaoAcessoFornecedorComponent,
  NFeRecebimentoBrowseComponent,
  LoteContaReceberBrowseComponent,
  CobrancaBaixaEmLoteComponent,
  ConfigGeralBrowseComponent,
  CaixaSATBrowseComponent,
  CFeBrowseComponent,
  RelatVendasPorDiaComponent,
  RelatProdutosSemVendaComponent,
  RelatListagemProdutosComponent,
  RelatRankingProdutosComponent,
  RelatVendasDetalhadoComponent,
  FechamentoCaixaBrowseComponent,
  NFeConsignacaoBrowseComponent,
  RelatProdutosConsignacaoComponent,
  GrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
  CupomDescontoBrowseComponent,
  NFeTrocaProdutoBrowseComponent,
  PromocaoBrowseComponent,
  RelatorioCustomizadoBrowseComponent,
  MarketplaceBrowseComponent,
  ProdutoMarketplaceBrowseComponent,
  NFeImportacaoMarketplaceBrowseComponent,
  RelatVendasPorMesComponent,
  RelatUltimoLoginComponent,
  NFCeBrowseComponent,
  RelatMovimentoDiarioComponent,

  //@Raul
  CategoriaContaContabilBrowse,
  ContaContabilBrowseComponent,
  ProdutoAlteraEmLoteComponent,
  EmpresaPrecoBrowseComponent,
  RelatContasContabeisComponent,
  OrcamentoBrowseComponent,
  RelatDRELiquidacaoVerticalComponent,
  ContaBancariaBrowseComponent,
  RelatMovimentacoesComponent,
  RelatDadosAssinaturasComponent,
  //LandingPageComponent,
  SpedFiscalComponent,
  FluxoDeCaixaBrowseComponent,
  ContaPagarReceberBrowseComponent,
  RelatContaPagarReceberComponent,
  ChequeBaixaEmLoteComponent,
  CartaoBaixaEmLoteComponent,  
  WizardConfigComponent,
  ContaPagarReceberConsultaBrowseComponent,
  PDVComponent,
  PreVendaEditComponent,
  ProdutoGerarCodigoDeBarrasComponent,

  //@Bruno
  ConfiguracaoBoletoBrowseComponent,
  GeracaoArquivoRemessaComponent,
  RelatBoletoImpressaoComponent,
  RetornoProcessamentoComponent,
  ExportarXmlComponent,
  RelatExportarDadosComponent,
} from "./index";

const recursosRoutes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "destaques", component: HomeDestaquesComponent },
      { path: "consultas", component: HomeConsultasComponent },
      { path: "dashboard", component: HomeDashboardComponent },
      { path: "**", redirectTo: "destaques" },
    ],
  },
  { path: "home2", component: Home2Component, canActivate: [AuthGuard] },
  {
    path: "associado",
    component: AssociadoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "config-geral",
    component: ConfigGeralBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "empresa-associado",
    component: EmpresaAssociadoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "seguimento",
    component: SeguimentoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "grupodeproduto",
    component: GrupoDeProdutoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "subgrupodeproduto",
    component: SubGrupoDeProdutoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "grupodeoperacao",
    component: GrupoDeOperacaoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "minhaconta/pagarfatura",
    component: MinhaContaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "minhaconta",
    component: MinhaContaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "municipio",
    component: MunicipioBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "produto",
    component: ProdutoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "pessoa/:cpf",
    component: ClienteBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "pessoa",
    component: ClienteBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "formapagamento",
    component: FormaPagamentoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "prevenda",
    component: PreVendaBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "pdv",
    component: PDVComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "conta-pagar-receber",
    component: ContaPagarReceberBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "conta-pagar-receber-consulta",
    component: ContaPagarReceberConsultaBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "fluxodecaixa",
    component: FluxoDeCaixaBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "duplicatareceber",
    component: DuplicataReceberBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "duplicatapagar",
    component: DuplicataPagarBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "contapagar",
    component: ContaPagarBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "ibpt",
    component: IBPTBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "padraodeoperacao",
    component: PadraoDeOperacaoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "cest",
    component: CESTBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "nfe",
    component: NFeBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "entrada-saida-estoque",
    component: NFeEstoqueBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "cfop",
    component: CFOPBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "nfeinutilizacao",
    component: NFeInutilizacaoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "curriculum",
    component: CurriculumBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "geracaoarquivocnab",
    component: GeracaoArquivoCNABComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ajustesaldo",
    component: AjusteSaldoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "grade/:indice",
    component: GradeBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "necessidade-compra",
    component: NecessidadeCompraBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "ordem-compra",
    component: OrdemCompraBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "cotacao",
    component: CotacaoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "cotacao-acesso-fornecedor/:chaveacesso",
    component: CotacaoAcessoFornecedorComponent,
  },
  {
    path: "nfe-recebimento",
    component: NFeRecebimentoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "consignacao",
    component: NFeConsignacaoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "trocaproduto",
    component: NFeTrocaProdutoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "lote-conta-receber",
    component: LoteContaReceberBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "cobranca-baixa-em-lote",
    component: CobrancaBaixaEmLoteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cheque-baixa-em-lote",
    component: ChequeBaixaEmLoteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cartao-baixa-em-lote",
    component: CartaoBaixaEmLoteComponent,
    canActivate: [AuthGuard],
  },

  //@Raul
  {
    path: "altera-em-lote-produto",
    component: ProdutoAlteraEmLoteComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },

  {
    path: "wizard-config",
    component: WizardConfigComponent,
    canActivate: [AuthGuard], 
  },

  {
    path: "sped-fiscal",
    component: SpedFiscalComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  
  {
    path: "exportar-xmls-nfe",
    component: ExportarXmlComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  
  {
    path: "produto-gerar-codigo-de-barras",
    component: ProdutoGerarCodigoDeBarrasComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },

  {
    path: "caixa-sat",
    component: CaixaSATBrowseComponent,
    canActivate: [AuthGuard],
  },

  { path: "cfe", component: CFeBrowseComponent, canActivate: [AuthGuard] },

  {
    path: "fechamento-caixa",
    component: FechamentoCaixaBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cupomdesconto",
    component: CupomDescontoBrowseComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "relatemissaocarne",
    component: RelatEmissaoCarneComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatcontareceber",
    component: RelatContaReceberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatcontapagarreceber",
    component: RelatContaPagarReceberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatclientesaniversariantes",
    component: RelatClientesAniversariantesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatcontapagar",
    component: RelatContaPagarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatfluxocaixafuturo",
    component: RelatFluxoCaixaFuturoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatboleto",
    component: RelatBoletoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatusoplataforma",
    component: RelatUsoPlataformaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatultimologin",
    component: RelatUltimoLoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatposicaoestoque",
    component: RelatPosicaoEstoqueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatvendaspordia",
    component: RelatVendasPorDiaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatvendaspormes",
    component: RelatVendasPorMesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatvendasdetalhado",
    component: RelatVendasDetalhadoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatprodutossemvenda",
    component: RelatProdutosSemVendaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatlistagemprodutos",
    component: RelatListagemProdutosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatrankingprodutos",
    component: RelatRankingProdutosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatprodutosconsignacao",
    component: RelatProdutosConsignacaoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "importacoes",
    component: ImportacoesCNABComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "promocao",
    component: PromocaoBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatorio-customizado",
    component: RelatorioCustomizadoBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "marketplace",
    component: MarketplaceBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "produto-marketplace",
    component: ProdutoMarketplaceBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "importacao-marketplace",
    component: NFeImportacaoMarketplaceBrowseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "nfce",
    component: NFCeBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "relatmovimentodiario",
    component: RelatMovimentoDiarioComponent,
    canActivate: [AuthGuard],
  },

  //@Raul
  {
    path: "contabancaria",
    component: ContaBancariaBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "categoriacontacontabil",
    component: CategoriaContaContabilBrowse,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "contacontabil",
    component: ContaContabilBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "relatcontacontabil",
    component: RelatContasContabeisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatdreliquidacaovertical",
    component: RelatDRELiquidacaoVerticalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatmovimentacoes",
    component: RelatMovimentacoesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "empresapreco",
    component: EmpresaPrecoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: "orcamento",
    component: OrcamentoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: "relatdadosassinatura",
    component: RelatDadosAssinaturasComponent,
    canActivate: [AuthGuard],
  },
  /*
  {
    path: "novafunc",
    component: NovaFuncBrowse,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  */
  //@Bruno
  {
    path: "configuracaoboleto",
    component: ConfiguracaoBoletoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "geracaoarquivoremessa",
    component: GeracaoArquivoRemessaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatboletoimpressao",
    component: RelatBoletoImpressaoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "relatexportardados",
    component: RelatExportarDadosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "retornoprocessamento",
    component: RetornoProcessamentoComponent,
    canActivate: [AuthGuard],
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "home" },
];

export const routerOptions = {};
export const recursos_routing = RouterModule.forRoot(recursosRoutes);
