// tslint:disable
import { Component, Injector } from "@angular/core";
import { CMInputConfig, CMValidatorEmail } from "src/app/@cmcore/component";
import {
  CMFormEditComponent,
  CMFormGlobaisComponent,
  CMFormModalComponent,
} from "src/app/@cmcore/component/cmform";
import { CFOPBrowseComponent } from "../cfop";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "wizard-config",
  templateUrl: "wizard-config.component.html",
  styleUrls: ["wizard-config.component.scss"],
})
export class WizardConfigComponent extends CMFormEditComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "empresaassociado";
    this.setModelConfig();
    setTimeout(() => {
      this.getModelData(Number.parseInt(FuncsGlobais.paramEmpresa.idEmpresa), (model) => {
        this.dadosWizard(model)
      });
    }, 10);
  }

  checkList = {
    carregou: false,
    parametroemail: false,
    parametronfe: false,
    certificadodigital: false,
    pessoa: false,
    produto: false,
    prevenda: false,
    nfe: false
  }

  dadosWizard(model) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/empresaassociado/DadosWizard",
      JSON.stringify({id: this.model.id}),
      (result) => {
        this.checkList.carregou = true;
        this.checkList.parametroemail = result['parametroemail'] == 'true';
        this.checkList.parametronfe = result['parametronfe'] == 'true';
        this.checkList.certificadodigital = result['certificadodigital'] == 'true';
        this.checkList.pessoa = result['pessoa'] == 'true';
        this.checkList.produto = result['produto'] == 'true';
        this.checkList.prevenda = result['prevenda'] == 'true';
        this.checkList.nfe = result['nfe'] == 'true';
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  setModelConfig() {
    this.modelConfig = {
      parametroemail: {
        email: new CMInputConfig({
          form: this,
          caption: "e-Mail",
          maxlength: 100,
          requiredFunc: () => this.isFormParametroEmail(),
          customvalidators: [new CMValidatorEmail()],
        }),
        smtp: new CMInputConfig({
          form: this,
          caption: "Servidor (SMTP)",
          maxlength: 50,
          requiredFunc: () => this.isFormParametroEmail(),
        }),
        portsmtp: new CMInputConfig({
          form: this,
          caption: "Porta",
          dataType: "integer",
          requiredFunc: () => this.isFormParametroEmail(),
        }),
        protocol: new CMInputConfig({
          form: this,
          caption: "Protocolo",
          requiredFunc: () => this.isFormParametroEmail(),
          values: [
            { value: "Nenhum" },
            { value: "Padrao", text: "Padrão" },
            { value: "SSL" },
            { value: "TLS" },
            { value: "TLSv1" },
            { value: "TLSv1R", text: "TSLv1 requerido" },
            { value: "STARTTLS" },
          ],
        }),
        usernamesmtp: new CMInputConfig({
          form: this,
          caption: "Usuário",
          maxlength: 100,
          requiredFunc: () => this.isFormParametroEmail(),
        }),
        passwordsmtp: new CMInputConfig({
          form: this,
          caption: "Senha",
          maxlength: 50,
          requiredFunc: () => this.isFormParametroEmail(),
          criptografado: true,
        }),
        mensagememailpadrao: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão",
          requiredFunc: () => this.isFormParametroEmail(),
        }),
      },
      parametronfe: {
        regimetrib: new CMInputConfig({
          form: this,
          caption: "Regime tributário",
          requiredFunc: () => this.isFormParametroNFe(),
          values: [
            { value: "SimplesNacional", text: "Simples Nacional" },
            { value: "SimplesNacionalSubLimite", text: "Simples Nacional Sublimite"},
			{ value: "SimplesNacionalMEI", text: "Simples Nacional Microempreendedor Individual – MEI"},
          ],
        }),
        tpamb: new CMInputConfig({
          form: this,
          caption: "Tipo de Ambiente",
          requiredFunc: () => this.isFormParametroNFe(),
          values: [
            { value: "Producao", text: "Produção" },
            { value: "Homologacao", text: "Homologação" },
          ],
        }),
        serie: new CMInputConfig({
          form: this,
          caption: "Série",
          dataType: "integer",
          requiredFunc: () => this.isFormParametroNFe(),
        }),
        cfoppadrao: new CMInputConfig({
          form: this,
          caption: "CFOP Padrão",
          mask: "9.999",
          requiredFunc: () => this.isFormParametroNFe(),
          pesqFunc: (sender) => {
            CFOPBrowseComponent.pesquisa(
              sender,
              this.model.parametronfe,
              "cfoppadrao"
            );
          },
        }),
        nrultimanf: new CMInputConfig({
          form: this,
          caption: "Produção",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnota === "true";
          },
        }),
        nrultimanfhomologacao: new CMInputConfig({
          form: this,
          caption: "Homologação",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnotahomologacao === "true";
          },
        }),
        pcredsn: new CMInputConfig({
          form: this,
          caption:
            "Alíquota aplicável de cálculo do crédito (Simples Nacional)",
          dataType: "double(2)",
        }),
        obssimples: new CMInputConfig({
          form: this,
          caption: "Observação do Simples",
          requiredFunc: () => this.isFormParametroNFe(),
        }),
        senhacertificadodigital: new CMInputConfig({
          form: this,
          caption: "Senha",
          requiredFunc: () => this.isFormCertificadoDigital(),
          criptografado: true,
        }),
        diasvalcertdigital: new CMInputConfig({
          form: this,
          caption: "Qtde. de dias para aviso do vencimento",
          requiredFunc: () => this.isFormCertificadoDigital(),
          dataType: "integer",
        }),
        datacertificadodigital: new CMInputConfig({
          form: this,
          caption: "Validade",
          requiredFunc: () => this.isFormCertificadoDigital(),
        }),
        infocertificadodigital: new CMInputConfig({ form: this }),
      },
    };
  }

  formModalNameAberto: string = "";
  openFormModal(name) {
    this.formModalNameAberto = name;
    CMFormModalComponent.open(name);
  }

  closeFormModal(name) {
    
    if (name === 'wizard-parametroemail'){
      if (
        (this.model.parametroemail.email        !== undefined) &&
        (this.model.parametroemail.smtp         !== undefined) &&
        (this.model.parametroemail.portsmtp     !== undefined) &&
        (this.model.parametroemail.protocol     !== undefined) &&
        (this.model.parametroemail.email        !== undefined) &&
        (this.model.parametroemail.usernamesmtp !== undefined) &&
        (this.model.parametroemail.passwordsmtp !== undefined)
      ){
        this.checkList.parametroemail = true;
      }
    }

    if (name === 'wizard-parametronfe'){
      if (this.model.parametronfe.cfoppadrao !== undefined){
        this.checkList.parametronfe = true;
      }
    }
    
    if (name === 'wizard-certificadodigital'){
      if (
        (this.model.parametronfe.senhacertificadodigital !== undefined) &&
        (this.model.parametronfe.diasvalcertdigital      !== undefined) &&
        (this.model.parametronfe.datacertificadodigital  !== undefined) &&
        (this.model.parametronfe.infocertificadodigital  !== undefined)
      ){
        this.checkList.certificadodigital = true;
      }
    }

    if (name === 'wizard-produto'){
      let componentProduto = document.querySelector('#wizard-produto');
      let totalProduto = componentProduto.getElementsByClassName('total')[0].firstChild.textContent;
      if (totalProduto !== '0 total'){
        this.checkList.produto = true;
      }
    }
    
    if (name === 'wizard-pessoa'){
      let componentPessoa = document.querySelector('#wizard-pessoa');
      let totalPessoa = componentPessoa.getElementsByClassName('total')[0].firstChild.textContent;
      if (totalPessoa !== '0 total'){
        this.checkList.pessoa = true;
      }
    }
    
    if (name === 'wizard-prevenda'){
      let componentPrevenda = document.querySelector('#wizard-prevenda');
      let totalPrevenda = componentPrevenda.getElementsByClassName('total')[0].firstChild.textContent;
      if (totalPrevenda !== '0 total'){
        this.checkList.prevenda = true;
      }
    }

    if (name === 'wizard-nfe'){
      let componentNFe = document.querySelector('#wizard-nfe');
      let totalNFe = componentNFe.getElementsByClassName('total')[0].firstChild.textContent;
      if (totalNFe !== '0 total'){
        this.checkList.nfe = true;
      }
    }

    console.log('this.model: ', this.model);

    this.formModalNameAberto = "";
    CMFormModalComponent.close(name);
  }

  saveFormModal() {
    console.log('curso de ingles');
    this.save(undefined);
  }

  isFormParametroEmail() {
    return this.formModalNameAberto == "wizard-parametroemail";
  }

  isFormParametroNFe() {
    return this.formModalNameAberto == "wizard-parametronfe";
  }

  isFormCertificadoDigital() {
    return this.formModalNameAberto == "wizard-certificadodigital";
  }

  afterSave() {
    this.closeFormModal(this.formModalNameAberto);
  }

  testeEnvioEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/empresaassociado/TesteEnvioEmail/FuncaoExtra",
      JSON.stringify(this.model.parametroemail),
      (result) => {
        if (result["result"] === "ok") {
          CMFormGlobaisComponent.toastSucesso(
            "Aguarde alguns minutos e verifique seu email"
          );
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Verifique a mensagem abaixo</b></p>";
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  carregarCertificadoDigital() {
    if (this.model.parametronfe.senhacertificadodigital === "")
      CMFormGlobaisComponent.toastErro(
        "Informe a senha do certificado digital"
      );
    else CMFormModalComponent.open("modalFormCertificado");
  }

  modalFormCertificadoClose() {
    CMFormModalComponent.close("modalFormCertificado");
  }

  onCompleteUpload(sender: any) {
    CMFormGlobaisComponent.showSpinner();
    sender.form.postSubscribe(
      "/api/empresaassociado/UploadCertificadoDigital/FuncaoExtra",
      JSON.stringify({
        keyserver: sender.keyserver,
        senha: sender.form.model.parametronfe.senhacertificadodigital,
        idempresa: sender.form.model.id,
      }),
      (result) => {
        if (result["result"] === "ok") {
          sender.form.model.parametronfe.datacertificadodigital =
            result["validade"];
          sender.form.model.parametronfe.infocertificadodigital =
            result["info"];
          CMFormGlobaisComponent.toastSucesso("Arquivo enviado com sucesso");
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  checkCertificadoDigitalConfigurado() {
    if (this.valid()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/empresaassociado/CheckCertificadoDigitalConfigurado/FuncaoExtra",
        JSON.stringify({
          idempresa: this.model.id,
          senha: this.model.parametronfe.senhacertificadodigital,
        }),
        (result) => {
          if (result["result"] === "ok") {
            CMFormGlobaisComponent.toastSucesso(
              "Certificado digital configurado corretamente"
            );
          } else {
            let str: string = '<p class="divider"></p><div class="container">';
            str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
            str += '</div><p class="divider"></p>';
            CMFormGlobaisComponent.showMessageAviso(str);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }
}
