// tslint:disable
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { CMCoreModule } from "./../@cmcore/cmcore.module";
import { AppComponent } from "./../app.component";
import { recursos_routing } from "./recursos.routing";
import {
  ComboChartComponent,
  ComboSeriesVerticalComponent,
} from "./home/home-dashboard/combo-chart";
import { AngularMaterialModule } from "./../../angular-material.module";
import {
  MenuPrincipalComponent,
  MenuLateralComponent,
  FooterPageComponent,
  AssociadoEditComponent,
  AssociadoBrowseComponent,
  SeguimentoEditComponent,
  SeguimentoBrowseComponent,
  MunicipioEditComponent,
  MunicipioBrowseComponent,
  ProdutoEditComponent,
  ProdutoBrowseComponent,
  ClienteEditComponent,
  ClienteBrowseComponent,
  FormaPagamentoEditComponent,
  FormaPagamentoBrowseComponent,
  PreVendaEditComponent,
  PreVendaBrowseComponent,
  RelatEmissaoCarneComponent,
  RelatClientesAniversariantesComponent,
  RelatContaReceberComponent,
  RelatContaPagarComponent,
  RelatFluxoCaixaFuturoComponent,
  DuplicataReceberBrowseComponent,
  DuplicataReceberEditComponent,
  DuplicataPagarBrowseComponent,
  DuplicataPagarEditComponent,
  ContaPagarBrowseComponent,
  ContaPagarEditComponent,
  IBPTBrowseComponent,
  IBPTEditComponent,
  HomeComponent,
  HomeConsultasComponent,
  HomeDashboardComponent,
  HomeDestaquesComponent,
  Home2Component,
  PadraoDeOperacaoBrowseComponent,
  PadraoDeOperacaoEditComponent,
  CESTBrowseComponent,
  CESTEditComponent,
  NFeBrowseComponent,
  NFeEditComponent,
  MinhaContaComponent,
  CFOPBrowseComponent,
  CFOPEditComponent,
  NFeAcoesComponent,
  PessoaBrowseComponent,
  NFeInutilizacaoBrowseComponent,
  NFeInutilizacaoEditComponent,
  NFeInutilizacaoAcoesComponent,
  CurriculumBrowseComponent,
  RelatBoletoComponent,
  RelatUsoPlataformaComponent,
  RelatUltimoLoginComponent,
  GeracaoArquivoCNABComponent,
  ImportacoesCNABComponent,
  RelatPosicaoEstoqueComponent,
  AjusteSaldoComponent,
  EmpresaAssociadoEditComponent,
  EmpresaAssociadoBrowseComponent,
  GradeEditComponent,
  GradeBrowseComponent,
  NFeEstoqueEditComponent,
  NFeEstoqueBrowseComponent,
  NecessidadeCompraBrowseComponent,
  NecessidadeCompraEditComponent,
  OrdemCompraEditComponent,
  OrdemCompraBrowseComponent,
  CotacaoBrowseComponent,
  CotacaoEditComponent,
  CotacaoAcessoFornecedorComponent,
  CotacaoAnaliseComponent,
  NFeRecebimentoBrowseComponent,
  NFeRecebimentoEditComponent,
  LoteContaReceberBrowseComponent,
  LoteContaReceberEditComponent,
  CobrancaBaixaEmLoteComponent,
  CotacaoEmailComponent,
  NFeRecebimentoAcoesComponent,
  ConfigGeralBrowseComponent,
  ConfigGeralEditComponent,
  CaixaSATBrowseComponent,
  CaixaSATEditComponent,
  CFeBrowseComponent,
  CFeEditComponent,
  RelatVendasPorDiaComponent,
  RelatProdutosSemVendaComponent,
  RelatListagemProdutosComponent,
  RelatRankingProdutosComponent,
  GrupoDeOperacaoBrowseComponent,
  GrupoDeOperacaoEditComponent,
  RelatVendasDetalhadoComponent,
  FechamentoCaixaBrowseComponent,
  FechamentoCaixaEditComponent,
  NFeConsignacaoBrowseComponent,
  NFeConsignacaoEditComponent,
  NFeConsignacaoFechamentoComponent,
  RelatProdutosConsignacaoComponent,
  GrupoDeProdutoBrowseComponent,
  GrupoDeProdutoEditComponent,
  SubGrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoEditComponent,
  ProdutoDetalhesSaldoComponent,
  CupomDescontoBrowseComponent,
  CupomDescontoEditComponent,
  NFeTrocaProdutoEditComponent,
  NFeTrocaProdutoBrowseComponent,
  PromocaoBrowseComponent,
  PromocaoEditComponent,
  RelatorioCustomizadoBrowseComponent,
  RelatorioCustomizadoEditComponent,
  MarketplaceBrowseComponent,
  MarketplaceEditComponent,
  ProdutoMarketplaceBrowseComponent,
  ProdutoMarketplaceEditComponent,
  NFeImportacaoMarketplaceBrowseComponent,
  NFeImportacaoMarketplaceEditComponent,
  NFeRecebimentoAlterarDataDuplicataComponent,
  RelatVendasPorMesComponent,
  SolicitaSenhaSupervisor,
  NFCeAcoesComponent,
  NFCeBrowseComponent,
  NFCeEditComponent,
  RelatMovimentoDiarioComponent,

  //@Raul
  CategoriaContaContabilBrowse,
  CategoriaContaContabilEditComponent,
  ContaContabilBrowseComponent,
  ContaContabilEditComponent,
  ProdutoAlteraEmLoteComponent,
  EmpresaPrecoBrowseComponent,
  EmpresaPrecoEditComponent,
  RelatContasContabeisComponent,
  OrcamentoEditComponent,
  OrcamentoBrowseComponent,
  RelatDRELiquidacaoVerticalComponent,
  PreVendaRelatoriosComponent,
  OrdemCompraRelatoriosComponent,
  CupomDescontoRelatoriosComponent,
  DuplicataReceberRelatoriosComponent,
  NFeConsignacaoRelatoriosComponent,
  OrcamentoRelatoriosComponent,
  NFeRelatoriosComponent,
  NFeRecebimentoRelatoriosComponent,
  ProdutoRelatoriosComponent,
  ContaBancariaBrowseComponent,
  ContaBancariaEditComponent,
  RelatMovimentacoesComponent,
  PreVendaGerarSaidaEstoqueComponent,
  RelatDadosAssinaturasComponent,
  //LandingPageComponent,
  SpedFiscalComponent,
  FluxoDeCaixaBrowseComponent,
  FluxoDeCaixaEditComponent,
  ContaPagarReceberBrowseComponent,
  ContaPagarReceberEditComponent,
  RelatContaPagarReceberComponent,
  WizardConfigComponent,
  ChequeBaixaEmLoteComponent,
  ContaPagarReceberBaixaOutrosComponent,
  CartaoBaixaEmLoteComponent,
  ExportarXmlComponent,
  ContaPagarReceberConsultaBrowseComponent,
  ContaPagarReceberConsultaEditComponent,
  PDVComponent,
  PDVAcoesComponent,
  ProdutoGerarCodigoDeBarrasComponent,
  //NovaFuncBrowse,
  //NovaFuncEditComponent,  

  //@Bruno
  ConfiguracaoBoletoBrowseComponent,
  ConfiguracaoBoletoEditComponent,
  GeracaoArquivoRemessaComponent,
  RelatBoletoImpressaoComponent,
  RetornoProcessamentoComponent,
  RelatExportarDadosComponent,

} from "./index";
import { LoginComponent } from "../@cmcore/guard";

//AppComponent.title = "ACIRC.NET";
AppComponent.title = "BESSANI.WEB"; // ALTERAR_BESSWEB
AppComponent.useMenuLateral2 = true;
LoginComponent.useEsqueceuSenha = true;
AppComponent.useMenuPrincipalInRouterOutlet = true;
AppComponent.heightRouterOutlet = "calc(100% - 64px)";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    CMCoreModule,
    AngularMaterialModule,
    recursos_routing,
  ],
  declarations: [
    HomeComponent,
    HomeConsultasComponent,
    HomeDashboardComponent,
    HomeDestaquesComponent,
    Home2Component,
    MenuPrincipalComponent,
    MenuLateralComponent,
    FooterPageComponent,
    AssociadoBrowseComponent,
    AssociadoEditComponent,
    SeguimentoEditComponent,
    SeguimentoBrowseComponent,
    MinhaContaComponent,
    MunicipioEditComponent,
    MunicipioBrowseComponent,
    ProdutoEditComponent,
    ProdutoBrowseComponent,
    ClienteEditComponent,
    ClienteBrowseComponent,
    FormaPagamentoEditComponent,
    FormaPagamentoBrowseComponent,
    PreVendaEditComponent,
    PreVendaBrowseComponent,
    DuplicataReceberBrowseComponent,
    DuplicataReceberEditComponent,
    DuplicataPagarBrowseComponent,
    DuplicataPagarEditComponent,
    ContaPagarBrowseComponent,
    ContaPagarEditComponent,
    RelatClientesAniversariantesComponent,
    RelatContaReceberComponent,
    RelatEmissaoCarneComponent,
    RelatContaPagarComponent,
    RelatFluxoCaixaFuturoComponent,
    IBPTBrowseComponent,
    IBPTEditComponent,
    PadraoDeOperacaoBrowseComponent,
    PadraoDeOperacaoEditComponent,
    CESTBrowseComponent,
    CESTEditComponent,
    NFeBrowseComponent,
    NFeEditComponent,
    NFeAcoesComponent,
    CFOPBrowseComponent,
    CFOPEditComponent,
    PessoaBrowseComponent,
    NFeInutilizacaoBrowseComponent,
    NFeInutilizacaoEditComponent,
    NFeInutilizacaoAcoesComponent,
    CurriculumBrowseComponent,
    RelatBoletoComponent,
    RelatUsoPlataformaComponent,
    RelatUltimoLoginComponent,
    GeracaoArquivoCNABComponent,
    ImportacoesCNABComponent,
    NFeEstoqueEditComponent,
    NFeEstoqueBrowseComponent,
    RelatPosicaoEstoqueComponent,
    AjusteSaldoComponent,
    EmpresaAssociadoEditComponent,
    EmpresaAssociadoBrowseComponent,
    GradeEditComponent,
    GradeBrowseComponent,
    NecessidadeCompraBrowseComponent,
    NecessidadeCompraEditComponent,
    OrdemCompraBrowseComponent,
    OrdemCompraEditComponent,
    CotacaoBrowseComponent,
    CotacaoEditComponent,
    CotacaoAcessoFornecedorComponent,
    CotacaoAnaliseComponent,
    NFeRecebimentoBrowseComponent,
    NFeRecebimentoEditComponent,
    LoteContaReceberBrowseComponent,
    LoteContaReceberEditComponent,
    CobrancaBaixaEmLoteComponent,
    CotacaoEmailComponent,
    NFeRecebimentoAcoesComponent,
    ConfigGeralBrowseComponent,
    ConfigGeralEditComponent,
    CaixaSATBrowseComponent,
    CaixaSATEditComponent,
    CFeBrowseComponent,
    CFeEditComponent,
    RelatVendasPorDiaComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    RelatProdutosSemVendaComponent,
    RelatListagemProdutosComponent,
    RelatRankingProdutosComponent,
    GrupoDeOperacaoBrowseComponent,
    GrupoDeOperacaoEditComponent,
    RelatVendasDetalhadoComponent,
    FechamentoCaixaBrowseComponent,
    FechamentoCaixaEditComponent,
    NFeConsignacaoBrowseComponent,
    NFeConsignacaoEditComponent,
    NFeConsignacaoFechamentoComponent,
    RelatProdutosConsignacaoComponent,
    GrupoDeProdutoBrowseComponent,
    GrupoDeProdutoEditComponent,
    SubGrupoDeProdutoBrowseComponent,
    SubGrupoDeProdutoEditComponent,
    ProdutoDetalhesSaldoComponent,
    CupomDescontoBrowseComponent,
    CupomDescontoEditComponent,
    NFeTrocaProdutoEditComponent,
    NFeTrocaProdutoBrowseComponent,
    PromocaoBrowseComponent,
    PromocaoEditComponent,
    RelatorioCustomizadoBrowseComponent,
    RelatorioCustomizadoEditComponent,
    MarketplaceBrowseComponent,
    MarketplaceEditComponent,
    ProdutoMarketplaceBrowseComponent,
    ProdutoMarketplaceEditComponent,
    NFeImportacaoMarketplaceBrowseComponent,
    NFeImportacaoMarketplaceEditComponent,
    NFeRecebimentoAlterarDataDuplicataComponent,
    RelatVendasPorMesComponent,
    SolicitaSenhaSupervisor,
    NFCeAcoesComponent,
    NFCeBrowseComponent,
    NFCeEditComponent,
    RelatMovimentoDiarioComponent,

    //@Raul
    CategoriaContaContabilBrowse,
    CategoriaContaContabilEditComponent,
    ContaContabilBrowseComponent,
    ContaContabilEditComponent,
    ProdutoAlteraEmLoteComponent,
    EmpresaPrecoBrowseComponent,
    EmpresaPrecoEditComponent,
    RelatContasContabeisComponent,
    OrcamentoEditComponent,
    OrcamentoBrowseComponent,
    RelatDRELiquidacaoVerticalComponent,
    PreVendaRelatoriosComponent,
    OrdemCompraRelatoriosComponent,
    CupomDescontoRelatoriosComponent,
    DuplicataReceberRelatoriosComponent,
    NFeConsignacaoRelatoriosComponent,
    OrcamentoRelatoriosComponent,
    NFeRelatoriosComponent,
    NFeRecebimentoRelatoriosComponent,
    ProdutoRelatoriosComponent,
    ContaBancariaBrowseComponent,
    ContaBancariaEditComponent,
    RelatMovimentacoesComponent,
    PreVendaGerarSaidaEstoqueComponent,
    RelatDadosAssinaturasComponent,
    //LandingPageComponent,
    SpedFiscalComponent,
    FluxoDeCaixaBrowseComponent,
    FluxoDeCaixaEditComponent,
    ContaPagarReceberBrowseComponent,
    ContaPagarReceberEditComponent,
    RelatContaPagarReceberComponent,    
    WizardConfigComponent,
    ChequeBaixaEmLoteComponent,  
    ContaPagarReceberBaixaOutrosComponent,
    CartaoBaixaEmLoteComponent,
    ContaPagarReceberConsultaBrowseComponent,
    ContaPagarReceberConsultaEditComponent,  
    PDVComponent,
    PDVAcoesComponent,
    ProdutoGerarCodigoDeBarrasComponent,
    //NovaFuncBrowse,
    //NovaFuncEditComponent,

    //@Bruno
    ConfiguracaoBoletoBrowseComponent,
    ConfiguracaoBoletoEditComponent,
    GeracaoArquivoRemessaComponent,
    RelatBoletoImpressaoComponent,
    RetornoProcessamentoComponent,
    RelatExportarDadosComponent,
    ExportarXmlComponent,
  ],
  entryComponents: [
    AssociadoEditComponent,
    SeguimentoEditComponent,
    GrupoDeOperacaoEditComponent,
    MunicipioEditComponent,
    ProdutoEditComponent,
    ClienteEditComponent,
    FormaPagamentoEditComponent,
    PreVendaEditComponent,
    DuplicataReceberEditComponent,
    DuplicataPagarEditComponent,
    ContaPagarEditComponent,
    IBPTEditComponent,
    PadraoDeOperacaoEditComponent,
    CESTEditComponent,
    NFeEditComponent,
    NFeAcoesComponent,
    CFOPEditComponent,
    NFeInutilizacaoEditComponent,
    NFeInutilizacaoAcoesComponent,
    NFeEstoqueEditComponent,
    EmpresaAssociadoEditComponent,
    GradeEditComponent,
    NecessidadeCompraEditComponent,
    OrdemCompraEditComponent,
    CotacaoEditComponent,
    CotacaoAnaliseComponent,
    NFeRecebimentoEditComponent,
    LoteContaReceberEditComponent,
    CotacaoEmailComponent,
    NFeRecebimentoAcoesComponent,
    ConfigGeralEditComponent,
    CaixaSATEditComponent,
    CFeEditComponent,
    FechamentoCaixaEditComponent,
    NFeConsignacaoEditComponent,
    NFeConsignacaoFechamentoComponent,
    GrupoDeProdutoEditComponent,
    SubGrupoDeProdutoEditComponent,
    ProdutoDetalhesSaldoComponent,
    CupomDescontoEditComponent,
    NFeTrocaProdutoEditComponent,
    PromocaoEditComponent,
    RelatorioCustomizadoEditComponent,
    MarketplaceBrowseComponent,
    MarketplaceEditComponent,
    ProdutoMarketplaceEditComponent,
    NFeImportacaoMarketplaceEditComponent,
    NFeRecebimentoAlterarDataDuplicataComponent,
    SolicitaSenhaSupervisor,
    NFCeAcoesComponent,
    NFCeEditComponent,
    
    //@Raul
    CategoriaContaContabilEditComponent,
    ContaContabilEditComponent,
    EmpresaAssociadoEditComponent,
    OrcamentoEditComponent,
    PreVendaRelatoriosComponent,
    OrdemCompraRelatoriosComponent,
    CupomDescontoRelatoriosComponent,
    DuplicataReceberRelatoriosComponent,
    NFeConsignacaoRelatoriosComponent,
    OrcamentoRelatoriosComponent,
    NFeRelatoriosComponent,
    NFeRecebimentoRelatoriosComponent,
    ProdutoRelatoriosComponent,
    ContaBancariaEditComponent,
    PreVendaGerarSaidaEstoqueComponent,
    FluxoDeCaixaEditComponent,
    ContaPagarReceberEditComponent,
    WizardConfigComponent,
    ContaPagarReceberBaixaOutrosComponent,
    ContaPagarReceberConsultaEditComponent,
    PDVComponent,
    PDVAcoesComponent,
    ProdutoGerarCodigoDeBarrasComponent,
    //NovaFuncEditComponent, 
    
    //@Bruno
    ConfiguracaoBoletoEditComponent,
  ],
  providers: [],
  exports: [MenuPrincipalComponent, MenuLateralComponent, FooterPageComponent],
})
export class RecursosModule {}
